.headerContainer {
    /* border: 1px solid red; */
    position: relative;

    /* text-align: center; */
    line-height: 100px;
}

.mainTitle {
    /* display: inline-block; */
    /* vertical-align: middle; */
    font-size: 32px;
    text-align: center;
}

.mainTitle .titleBr {
    display: none;
}

.sideLink {
    display: flex;
    gap: 32px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: 32px;
    line-height: 32px;
}

@media screen and (max-width: 780px) {
    .headerContainer {
        display: flex;
        margin: 24px 0;

    }

    .sideLink {
        display: block;
        font-size: 24px;
    }

    .mainTitle {
        line-height: normal;
        font-size: 24px;
    }

    .mainTitle .titleBr {
        display: block;
    }
}




.HeaderNav {
    background: transparent;
    border-radius: 3px;
    text-decoration: none;
    position: relative;
    z-index: 1;
    transition: .3s;
    padding-right: 32px;

    @media screen and (max-width: 959px) {
        padding-right: 0;
        
    }
}

.HeaderNav::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background: #161616;
    transition: transform normal .2s;
    transform-origin: 100% 50%;
    transform: scaleX(0);
}

.HeaderNav:hover {
    color: #EEE;
}

.HeaderNav:hover::before {
    transform-origin: 0% 50%;
    transform: scaleX(1);
}