@font-face {
    font-family: 'AdventPro-Light';
    src: url('../assets/fonts/advent-pro/AdventPro-Light.ttf');
}

@font-face {
    font-family: 'Mono-space';
    src: url('../assets/fonts/space-mono/SpaceMono-Regular.ttf');
}

@font-face {
    font-family: 'sf-ui-bold';
    src: url('../assets/fonts/sf-ui-display-cufonfonts/sf-ui-display-bold-58646a511e3d9.otf');
}

@font-face {
    font-family: 'sf-ui-heavy';
    src: url('../assets/fonts/sf-ui-display-cufonfonts/sf-ui-display-heavy-586470160b9e5.otf');
}

@font-face {
    font-family: 'sf-ui-medium';
    src: url('../assets/fonts/sf-ui-display-cufonfonts/sf-ui-display-medium-58646be638f96.otf');
}

@font-face {
    font-family: 'sf-ui-light';
    src: url('../assets/fonts/sf-ui-display-cufonfonts/sf-ui-display-light-58646b33e0551.otf');
}

body {
    background-color: #EEE;
    color: #161616;
    /* padding: 0 50px; */
    transition: background-color 1s ease;
    transition: color .5s ease;
}

h1 {
    font-family: 'AdventPro-Light';
}

h2 {
    font-family: 'font-family: 'ヒラギノ角ゴシック','Hiragino Sans',sans-serif;'
}

h3 {
    font-family: 'Mono-space';
}


@media screen and (max-width: 959px) {

}
@media screen and (max-width: 480px) {
	body {
        padding: 0 16px;
    }
}