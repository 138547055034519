.photoContainer {
  /* border: 1px solid red; */
}

.genreButtons {
  margin-bottom: 20px;
  font-size: 12px;
  font-family: 'Mono-space';
  display: flex;
  gap: 24px;
  justify-content: center;
  margin: 28px 0 56px 0;
}

.genreButtons .right {
  text-align: left;
  display: inline-flex;
  align-items: flex-start;
  flex-direction: column;
}

.genreButtons .right button {
  display: inline-block;
  margin-bottom: 5px;
  cursor: pointer;
  position: relative;
}

.genreButtons .right button::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #333;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.4s ease;
}

.genreButtons .right button:hover::after {
  transform: scaleX(1);
  background-color: #eee;
}




.my-masonry-grid {
  display: flex;
  margin-left: -8px; /* gutter size offset */
  width: auto;
}

.my-masonry-grid_column {
  padding-left: 8px; /* gutter size */
  background-clip: padding-box;
}

.gallery-item {
  margin-bottom: 8px; /* gutter size */
}

.gallery-item img {
  width: 100%;
  height: auto;
  display: block;
  filter: blur(10px); /* 画像のぼかし */
  opacity: 0.7; /* 画像の透明度 */
  transition: filter 0.5s ease, opacity 0.5s ease; /* トランジション */
}

.gallery-item img.loaded {
  filter: blur(0); /* ぼかしを解除 */
  opacity: 1; /* 不透明度を元に戻す */
}

.loadMoreContainer {
  text-align: center;
  margin: 20px 0;
}

.loadMoreContainer button {
  border: none;
  color: #eee;
  border-radius: 4px;
  cursor: pointer;
}

.gallery-item img {
  width: 100%;
  height: auto;
  display: block;
  filter: blur(10px); /* 画像のぼかし */
  opacity: 0.1; /* 画像の透明度 */
  transition: opacity 0.5s ease; /* 不透明度のトランジション */
}

.gallery-item img.loaded {
  animation: clearBlur 1s ease forwards; /* ぼかしを解除するアニメーション */
}

.pagination {
  display: flex;
  justify-content: center;
  gap: 24px;
  margin: 32px 0;
  font-family: 'AdventPro-Light';
  font-size: 32px;
}

.PaginationNav {
  background: transparent;
  border-radius: 3px;
  text-decoration: none;
  position: relative;
  z-index: 1;
  transition: .3s;
}

.PaginationNav:hover {
  color: #161616;
}

.PaginationNav:hover::before {
  transform-origin: 0% 50%;
  transform: scaleX(1);
}

.PaginationNav::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background: #eee;
  transition: transform normal .2s;
  transform-origin: 100% 50%;
  transform: scaleX(0);
}

@keyframes clearBlur {
  0% {
    filter: blur(10px);
    opacity: 0.7;
  }
  100% {
    filter: blur(0);
    opacity: 1;
  }
}


