.imgContainer {
    position: fixed;
    z-index: -100;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden; /* 画像がはみ出ないように隠す */
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #161616;
    opacity: 0;
    transition: opacity 1s ease-in-out;
    font-family: 'Mono-space';
    padding: 4%;
    padding-top: 6.5%;

    @media screen and (max-width: 959px) {
        padding-top: 24%;
    }
}

.imgContainer.visible {
    opacity: 1;
}

.TopImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 0.5s ease;
}

.TextArea {
    height: calc(88vh - 64px);
    /* margin-top: 100vh; */
    z-index: 100!important;
    /* border: 1px solid red; */

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    opacity: 0;
    transition: opacity 1s ease-in-out;

}

.TextArea.visible {
    opacity: 1;
}

.OnCenterTextArea {
    width: 40%;
    margin: 0 auto;
    /* border: 1px solid red; */

    @media screen and (max-width: 959px) {
        width: 80%;
    }
}

.OnCenterTextArea h2 {
    text-align: center;

    margin: 32px 0;
}

.OnCenterTextArea a {
    color: #EEE;

}

.UnderCenterTextArea {
    width: 40%;
    margin: 0 auto;
    /* border: 1px solid red; */

    @media screen and (max-width: 959px) {
        width: 80%;
    }
}

.UnderCenterTextArea h2 {
    text-align: center;

    margin: 32px 0;
}

.UnderCenterTextArea a {
    color: #EEE;
    
}

.Links {
    /* border: 1px solid red; */
    width: 40%;
    margin: 0 auto;
    text-align: center;
    display: flex;
    justify-content: space-around;
    font-size: 32px;

    @media screen and (max-width: 959px) {
        width: 80%;
    }
}

.Links .btn {
    cursor: pointer;

    &:hover {
        color: #c9127a;
        transition: all 0.5s;
    }
}

.loading {
    position: absolute;
    height: 100vh;
    top: 0;
    right: 0;
    left: 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    font-family: 'AdventPro-Light';
    z-index: 100;
    background-color: #EEE;
    transition: opacity 0.1s ease-in-out;
}

.loading .loadingContent {
    font-size: 3em;
}


.loading .loadingContent::before {
    content: attr(data-percentage);
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 0.5em;
    margin-top: 46px;
}

.loading.visible {
    opacity: 1;
    
}

.loading.hidden {
    display: none;
}